import React, { Component } from 'react';
import RenderInBrowser from 'react-render-in-browser';
import { Navbar, NavbarBrand, NavbarToggler, Nav, Collapse, NavItem, NavLink, UncontrolledDropdown, Dropdown, DropdownToggle, DropdownItem, DropdownMenu } from 'reactstrap';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobeEurope } from '@fortawesome/free-solid-svg-icons';

import AdjustableImage from './elements/AdjustableImage';
import WindowWidthListener from './elements/WindowWidthListener';
import DelayedLoading from './elements/DelayedLoading';

class Header extends Component {

    constructor (props) {
        super(props);

        this.state = {
            isNavOpen: false,
            isGuideDropdownOpen: false,
            isEuropeDrowdownOpen: false,
            isAsiaDropdownOpen: false,
            isNorthAmericaDropdownOpen: false,

            isTipsDropdownOpen: false,
            isReady: false
        }

        this.toggleNav = this.toggleNav.bind(this);
        this.openGuideDropdown = this.openGuideDropdown.bind(this);
        this.closeGuideDropdown = this.closeGuideDropdown.bind(this);
        this.openEuropeDropdown = this.openEuropeDropdown.bind(this);
        this.closeEuropeDropdown = this.closeEuropeDropdown.bind(this);
        this.openAsiaDropdown = this.openAsiaDropdown.bind(this);
        this.closeAsiaDropdown = this.closeAsiaDropdown.bind(this);
        this.openNorthAmericaDropdown = this.openNorthAmericaDropdown.bind(this);
        this.closeNorthAmericaDropdown = this.closeNorthAmericaDropdown.bind(this);

        this.openTipsDropdown = this.openTipsDropdown.bind(this);
        this.closeTipsDropdown = this.closeTipsDropdown.bind(this);

        this.topRef = React.createRef();
    }

    componentDidMount() {
        // Problem with duplicated code
        const children = document.body.children;
        for (var i = children.length - 1; i >= 0; i--) {
            if ((children[i].tagName === 'DIV' || children[i].tagName === 'LI' || children[i].tagName === 'UL') && children[i].id !== '___gatsby') {
                document.body.removeChild(children[i]);
            }
        }

        setTimeout(() => {
            this.setState({ isReady: true });
        }, 500);
    }

    componentDidUpdate() {
        // Problem with duplicated code
        const children = document.body.children;
        for (var i = children.length - 1; i >= 0; i--) {
            if ((children[i].tagName === 'DIV' || children[i].tagName === 'LI' || children[i].tagName === 'UL') && children[i].id !== '___gatsby') {
                document.body.removeChild(children[i]);
            }
        }
    }

    toggleNav() {
        this.setState({ isNavOpen: !this.state.isNavOpen });
    }

    openGuideDropdown() {
        this.setState({ isGuideDropdownOpen: true });
    }

    closeGuideDropdown () {
        this.setState({ isGuideDropdownOpen: false });
    }

    openEuropeDropdown() {
        this.setState({ isEuropeDrowdownOpen: true });
    }

    closeEuropeDropdown() {
        this.setState({ isEuropeDrowdownOpen: false });
    }

    openAsiaDropdown() {
        this.setState({ isAsiaDropdownOpen: true });
    }

    closeAsiaDropdown() {
        this.setState({ isAsiaDropdownOpen: false});
    }

    openNorthAmericaDropdown() {
        this.setState({ isNorthAmericaDropdownOpen: true });
    }

    closeNorthAmericaDropdown() {
        this.setState({ isNorthAmericaDropdownOpen: false });
    }

    openTipsDropdown() {
        this.setState({ isTipsDropdownOpen: true });
    }

    closeTipsDropdown() {
        this.setState({ isTipsDropdownOpen: false });
    }

    render() {
        const RenderGuides = ({open, close, isOpen, href, continent, data}) => {
            const DropdownItems = ({ countries }) => countries.map((country, key) => {
                return (
                    <React.Fragment key={ country + key }>
                        <DropdownItem className="dropdown-guide-continent">
                            <Link to={ country.link } style={{ textDecoration: 'inherit', color: 'inherit' }}>
                                <div className="dropdown-guide-continent__toggle">
                                    { country.name }
                                </div>
                            </Link>
                        </DropdownItem>
                    </React.Fragment>
                )
            });
            
            const { width } = WindowWidthListener();

            if (width > 720) {
                return (
                    <React.Fragment>
                        <Dropdown
                            direction="right"
                            onMouseEnter={ open }
                            onMouseLeave={ close }
                            isOpen={ isOpen } >
                            <DropdownToggle nav style={{ padding: "0px", margin: '0px' }}>
                                <Link to={ href } style={{ textDecoration: 'inherit', color: 'inherit' }}>
                                    <div className="dropdown-guide-continent__toggle">
                                        { continent }
                                    </div>
                                </Link>
                            </DropdownToggle>
                            <DropdownMenu
                                className="dropdown-guide"
                                style={{ marginLeft: '-1px', marginTop: '-1px' }}>
                                <DropdownItems countries={ data.countries } />                    
                            </DropdownMenu>
                        </Dropdown>
                    </React.Fragment>
                );
            } else {
                if (data.countries.length !== 0) {
                    return (
                    <React.Fragment>
                        <UncontrolledDropdown>
                            <DropdownToggle nav>
                                { continent }
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItems countries={ data.countries } />
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </React.Fragment> );
                } else {
                    return (
                    <React.Fragment>
                        <Link to={ href } style={{ textDecoration: 'inherit', color: 'inherit' }}>
                            <div className="dropdown-guide-continent__toggle">
                                { continent }
                            </div>
                        </Link>
                    </React.Fragment> );
                }
            }
        }

        const RenderTitle = () => {
            return (
                <div className="header-mainpage-div non-selectable">
                    <DelayedLoading delayTime={ 100 } isReady={ this.state.isReady }>
                        <h1 className="header-mainpage-title d-sm-none" style={{ fontSize: '1.5em', marginLeft: '-50%', marginRight: '-50%' }}>Bonded <span><FontAwesomeIcon icon={faGlobeEurope} /></span> World</h1>
                        <h1 className="header-mainpage-title d-none d-sm-block d-md-none" style={{ fontSize: '2.5em', marginLeft: '-50%', marginRight: '-50%' }}>Bonded <span><FontAwesomeIcon icon={faGlobeEurope} /></span> World</h1>
                        <h1 className="header-mainpage-title d-none d-md-block" style={{ marginLeft: '-50%', marginRight: '-50%'}}>Bonded <FontAwesomeIcon icon={faGlobeEurope} /> World</h1>
                    </DelayedLoading>
                </div>
            );
        }

        return (
            <div>
                <div id="page-top" className='header-topic'>
                    <AdjustableImage image="https://live.staticflickr.com/65535/50380559201_0e4dc1d173_o.jpg"
                        thumb="https://live.staticflickr.com/65535/50380559201_868b128a59_t.jpg"
                        name="BondedWorld" />
                    <div className="header-text">
                        <RenderTitle />
                    </div>
                </div>

                <Navbar light className="bg-light border" expand="sm">
                    <div className="container">
                        <NavbarBrand className="d-block d-sm-none" href="" onClick={() => { this.topRef.current.scrollIntoView() }}
                            style={{ fontFamily: 'Footlight MT Light, Helvetica, sans-serif' }}>
                            Bonded <FontAwesomeIcon icon={faGlobeEurope} /> World
                        </NavbarBrand>
                        <NavbarToggler onClick={ this.toggleNav } />
                        <Collapse isOpen={ this.state.isNavOpen } navbar>
                            <Nav navbar>
                                <Dropdown nav inNavbar
                                        onMouseEnter={ this.openGuideDropdown }
                                        onMouseLeave={ this.closeGuideDropdown }
                                        isOpen={ this.state.isGuideDropdownOpen }
                                        direction="down" >
                                    <DropdownToggle nav>
                                        Guides
                                    </DropdownToggle>


                                    <RenderInBrowser except ie edge>
                                        <DelayedLoading delayTime={ 1000 }>

                                            <DropdownMenu className="dropdown-guide" >
                                                <DropdownItem className="dropdown-guide-continent" >
                                                    <RenderGuides
                                                        open={ this.openEuropeDropdown }
                                                        close={ this.closeEuropeDropdown }
                                                        isOpen={ this.state.isEuropeDrowdownOpen }
                                                        href="/travel-guides/europe"
                                                        continent="Europe"
                                                        data={{
                                                            countries: [
                                                                { "name": "Malta", "link": "/travel-guides/europe/malta" },
                                                                { "name": "Scotland", "link": "/travel-guides/europe/scotland" },
                                                                { "name": "Barcelona", "link": "/travel-guides/europe/barcelona" },
                                                                { "name": "Budapest", "link": "/travel-guides/europe/budapest" },
                                                                { "name": "Krakow", "link": "/travel-guides/europe/krakow" },
                                                                { "name": "Paris", "link": "/travel-guides/europe/paris"},
                                                                { "name": "Prague", "link": "/travel-guides/europe/prague" }
                                                            ]}} />
                                                </DropdownItem>
                                                <DropdownItem className="dropdown-guide-continent" >
                                                    <RenderGuides
                                                        open={ this.openAsiaDropdown }
                                                        close={ this.closeAsiaDropdown }
                                                        isOpen={ this.state.isAsiaDropdownOpen }
                                                        href="/travel-guides/asia"
                                                        continent="Asia"
                                                        data={{
                                                            countries: [
                                                                { "name": "Vietnam", "link": "/travel-guides/asia/vietnam" }
                                                            ]}} />
                                                </DropdownItem>
                                                <DropdownItem className="dropdown-guide-continent" >
                                                    <RenderGuides
                                                        open={ this.openNorthAmericaDropdown }
                                                        close={ this.closeNorthAmericaDropdown }
                                                        isOpen={ this.state.isNorthAmericaDropdownOpen }
                                                        href="/travel-guides/north-america"
                                                        continent="North America"
                                                        data={{
                                                            countries: [
                                                                { "name": "Havana", "link": "/travel-guides/north-america/havana" },
                                                                { "name": "Miami", "link": "/travel-guides/north-america/miami" }
                                                            ]}} />
                                                </DropdownItem>
                                            </DropdownMenu>

                                        </DelayedLoading>
                                    </RenderInBrowser>
                                    <RenderInBrowser ie edge only>
                                        <DropdownMenu className="dropdown-guide" >
                                            <DropdownItem className="dropdown-guide-continent" >
                                                <RenderGuides
                                                    isOpen={ false }
                                                    href="/travel-guides/europe"
                                                    continent="Europe"
                                                    data={{ countries: [ ]}} />
                                            </DropdownItem>
                                            <DropdownItem className="dropdown-guide-continent" >
                                                <RenderGuides
                                                    isOpen={ false }
                                                    href="/travel-guides/asia"
                                                    continent="Asia"
                                                    data={{ countries: [ ]}} />
                                            </DropdownItem>
                                            <DropdownItem className="dropdown-guide-continent" >
                                                <RenderGuides
                                                    isOpen={ false }
                                                    href="/travel-guides/north-america"
                                                    continent="North America"
                                                    data={{ countries: [ ]}} />
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </RenderInBrowser>
                                </Dropdown>

                                <Dropdown nav inNavbar
                                        onMouseEnter={ this.openTipsDropdown }
                                        onMouseLeave={ this.closeTipsDropdown }
                                        isOpen={ this.state.isTipsDropdownOpen }
                                        toggle={ () => { } }
                                        direction="down" >
                                    <DropdownToggle nav >
                                        Travel Tips
                                    </DropdownToggle>

                                    <DropdownMenu className="dropdown-guide" >
                                        <DropdownItem className="dropdown-guide-continent" >
                                            <RenderGuides
                                                isOpen={ false }
                                                href="/travel-tips/money-saving"
                                                continent="Money Saving"
                                                data={{ countries: [ ]}} />
                                        </DropdownItem>
                                        <DropdownItem className="dropdown-guide-continent" >
                                            <RenderGuides
                                                isOpen={ false }
                                                href="/travel-tips/visas"
                                                continent="Visas"
                                                data={{ countries: [ ]}} />
                                        </DropdownItem>
                                        <DropdownItem className="dropdown-guide-continent" >
                                            <RenderGuides
                                                isOpen={ false }
                                                href="/travel-tips/flight-deals"
                                                continent="Flight Deals"
                                                data={{ countries: [ ]}} />
                                        </DropdownItem>
                                        <DropdownItem className="dropdown-guide-continent" >
                                            <RenderGuides
                                                isOpen={ false }
                                                href="/travel-tips/insurance"
                                                continent="Travel Insurance"
                                                data={{ countries: [ ]}} />
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <NavItem>
                                    <NavLink tag={ Link } to="/plan-my-trip">Plan My Trip</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={ Link } to="/aboutus">About Us</NavLink>
                                </NavItem>
                            </Nav>

                            <Nav navbar className="ms-auto">
                                <NavItem>
                                    <NavLink tag={ Link } to="/">Home</NavLink>
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </div>
                </Navbar>
            </div>
        );
    }

}

export default Header;
